import React, { useState, useEffect, useRef } from 'react'
import Moment from 'moment'
import ClockIcon from 'assets/icons/icon_clock.svg'

interface TimeInputProps {
  label: string
  // value en formato "HH:mm"
  value: string
  onChange: (value: string) => void
  className?: string
}

const TimeInput: React.FC<TimeInputProps> = ({ label, value, onChange, className }) => {
  // Inicializamos a partir del valor recibido (formato "HH:mm")
  const initialMoment = Moment(value, 'HH:mm')
  const [hours, setHours] = useState<number>(initialMoment.hour())
  const [minutes, setMinutes] = useState<number>(initialMoment.minute())
  const [meridian, setMeridian] = useState<'AM' | 'PM'>(initialMoment.hour() >= 12 ? 'PM' : 'AM')
  const [open, setOpen] = useState<boolean>(false)

  const containerRef = useRef<HTMLDivElement>(null)

  // Actualizamos el estado local si cambia el valor recibido
  useEffect(() => {
    const m = Moment(value, 'HH:mm')
    setHours(m.hour() % 12 === 0 ? 12 : m.hour() % 12)
    setMinutes(m.minute())
    setMeridian(m.hour() >= 12 ? 'PM' : 'AM')
  }, [value])

  // Cerrar el dropdown al hacer clic fuera
  useEffect(() => {
    function handleClickOutside(e: MouseEvent) {
      if (containerRef.current && !containerRef.current.contains(e.target as Node)) {
        setOpen(false)
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  // Función que actualiza el estado local y llama al onChange del padre
  const updateTime = (newHours: number, newMinutes: number, newMeridian: 'AM' | 'PM') => {
    setHours(newHours)
    setMinutes(newMinutes)
    setMeridian(newMeridian)
    // Convertir a formato 24h
    let h24 = newHours
    if (newMeridian === 'PM' && newHours < 12) {
      h24 = newHours + 12
    }
    if (newMeridian === 'AM' && newHours === 12) {
      h24 = 0
    }
    const hStr = String(h24).padStart(2, '0')
    const mStr = String(newMinutes).padStart(2, '0')
    const newValue = `${hStr}:${mStr}`
    onChange(newValue)
  }

  // Opciones para horas, minutos y meridiano
  const hoursOptions = Array.from({ length: 12 }, (_, i) => i + 1)
  const minutesOptions = Array.from({ length: 60 }, (_, i) => i)
  const meridianOptions: ('AM' | 'PM')[] = ['AM', 'PM']

  // Función para mostrar el valor en formato "hh:mm AM/PM"
  const displayValue = () => {
    const hStr = String(hours).padStart(2, '0')
    const mStr = String(minutes).padStart(2, '0')
    return `${hStr}:${mStr} ${meridian}`
  }

  return (
    <div ref={containerRef} className={`relative ${className || ''}`}>
      {/* Simulación del input */}
      <div
        onClick={() => setOpen(!open)}
        className={`
          w-full h-[56px] rounded-[16px] bg-grey-50 border
          flex items-center px-4 cursor-pointer relative
        `}
      >
        <label className={`absolute top-2 left-0 px-[16px] text-gray-600 text-caption`}>{label}</label>
        <span className="mt-4 flex-1 text-body1 text-grey-600">{displayValue()}</span>
        <img src={ClockIcon} className="w-[20px] h-[20px] ml-2" alt="clock icon" />
      </div>

      {open && (
        <div className="absolute z-10 bg-white border rounded-md mt-1 p-2 flex gap-2" style={{ minWidth: '200px' }}>
          {/* Columna de Horas */}
          <div className="flex flex-col max-h-48 overflow-y-auto">
            {hoursOptions.map(h => (
              <button
                key={h}
                onClick={() => updateTime(h, minutes, meridian)}
                className={`px-3 py-1 text-sm text-left ${h === hours ? 'bg-blue-500 text-white' : 'hover:bg-gray-100'}`}
              >
                {String(h).padStart(2, '0')}
              </button>
            ))}
          </div>
          {/* Columna de Minutos */}
          <div className="flex flex-col max-h-48 overflow-y-auto">
            {minutesOptions.map(m => (
              <button
                key={m}
                onClick={() => updateTime(hours, m, meridian)}
                className={`px-3 py-1 text-sm text-left ${m === minutes ? 'bg-blue-500 text-white' : 'hover:bg-gray-100'}`}
              >
                {String(m).padStart(2, '0')}
              </button>
            ))}
          </div>
          {/* Columna de AM/PM */}
          <div className="flex flex-col">
            {meridianOptions.map(mer => (
              <button
                key={mer}
                onClick={() => updateTime(hours, minutes, mer)}
                className={`px-3 py-1 text-sm text-left ${mer === meridian ? 'bg-blue-500 text-white' : 'hover:bg-gray-100'}`}
              >
                {mer}
              </button>
            ))}
          </div>
        </div>
      )}
    </div>
  )
}

export default TimeInput
