import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router'
import Moment from 'moment'

import IconDelete from 'assets/icons/icon_delete_a.svg'
import LabelForm from 'components/LabelForm'
import LoaderMini from 'components/LoaderMini'
import Button from 'components/Button'
import IconAdd from 'assets/icons/icon_add.svg'
import CalendarIcon from 'assets/icons/icon_calendar.svg'
import { onAlert } from 'components/Alert'

// Hooks para obtener y modificar disponibilidades
import {
  useGetAvailability,
  useCreateAvailability,
  useUpdateteAvailability,
  useDeleteAvailability,
} from 'features/UserManagement/hooks/userCalendar'
import AvailabilityModal from './AvailabilityModal'

interface IAvailabilityItem {
  month: number
  year: number
  days: number[]
  timeFrom?: string
  timeTo?: string
  update?: boolean
}

const FormUserCalendar: React.FC = () => {
  const params: any = useParams()

  const [listAvailability, setListAvailability] = useState<IAvailabilityItem[]>([])
  const [loadingData, setLoadingData] = useState(false)

  // Control de modal para crear/editar
  const [showModal, setShowModal] = useState(false)
  // Si editData es null, es creación; de lo contrario, edición
  const [editData, setEditData] = useState<any>(null)

  const { mutateAsync: getAvailability } = useGetAvailability()
  const { mutateAsync: createAvailability } = useCreateAvailability()
  const { mutateAsync: updateteAvailability } = useUpdateteAvailability()
  const { mutateAsync: deleteAvailability } = useDeleteAvailability()

  useEffect(() => {
    loadAvailabilityList()
    // eslint-disable-next-line
  }, [])

  const loadAvailabilityList = async () => {
    setLoadingData(true)
    const response = await getAvailability(params.userId)
    setLoadingData(false)
    if (response?.data) {
      setListAvailability(response.data)
    } else {
      onAlert.error(response?.data || response)
    }
  }

  // Se arma el payload final y se llama a la API
  const handleSaveAvailability = async (data: any) => {
    let days: number[] = []
    if (data.currentCalendarType === 1) {
      // Genera días desde startDateRange hasta endDateRange
      const start = new Date(data.startDateRange)
      const end = new Date(data.endDateRange)
      let current = new Date(start)
      while (current <= end) {
        days.push(current.getDate())
        current.setDate(current.getDate() + 1)
      }
    } else if (data.multiDate && data.multiDate.length > 0) {
      days = data.multiDate.map((d: Date) => d.getDate())
    }
    // Suponemos que el mes y año se toman del startDateRange
    const month = new Date(data.startDateRange).getMonth() + 1
    const year = new Date(data.startDateRange).getFullYear()

    const finalPayload = {
      update: data.update ? true : false,
      specialistsId: params.userId,
      body: {
        days,
        month,
        year,
        timeFrom: data.timeFrom || '08:00',
        timeTo: data.timeTo || '18:00',
      },
    }

    let response: any
    if (finalPayload.update) {
      response = await updateteAvailability(finalPayload)
    } else {
      response = await createAvailability(finalPayload)
    }
    if (response?.data) {
      onAlert.success('Fecha guardada')
      setShowModal(false)
      setEditData(null)
      loadAvailabilityList()
    } else {
      onAlert.error(response?.data || response)
    }
  }

  // Elimina disponibilidad usando mes y año
  const handleDeleteAvailability = async (item: IAvailabilityItem) => {
    const obj = {
      specialistsId: params.userId,
      body: {
        month: item.month,
        year: item.year,
      },
    }
    const responseDelete = await deleteAvailability(obj)
    if (responseDelete?.data) {
      onAlert.success('Fecha eliminada')
      loadAvailabilityList()
    } else {
      onAlert.error(responseDelete?.data || responseDelete)
    }
  }

  // Abre modal para crear nueva disponibilidad
  const handleAdd = () => {
    setEditData(null)
    setShowModal(true)
  }

  // Abre modal para editar disponibilidad existente
  const handleEdit = (item: IAvailabilityItem) => {
    setEditData({ ...item, update: true })
    setShowModal(true)
  }

  // Cierra el modal
  const toggleModal = () => {
    setShowModal(!showModal)
    setEditData(null)
  }

  return (
    <React.Fragment>
      <div className="flex items-center justify-between mb-4">
        <LabelForm icon={CalendarIcon} label="Disponibilidad" className="font-medium" />
        <Button
          type="button"
          variant="primary"
          onClick={handleAdd}
          className="flex items-center gap-2 px-4 py-2 text-sm rounded-[16px]"
        >
          <img src={IconAdd} alt="agregar" className="w-4 h-4" />
          <span>Agregar</span>
        </Button>
      </div>

      {loadingData && <LoaderMini />}

      <div className="grid lg:grid-cols-2 2xl:grid-cols-3 gap-4">
        {listAvailability.map((item, i) => {
          const sortedDays = (item.days || []).sort((a, b) => a - b)
          const areConsecutive = sortedDays.every((day, idx) => idx === 0 || day === sortedDays[idx - 1] + 1)

          let dateLabel = ''
          if (areConsecutive && sortedDays.length > 1) {
            const startDate = new Date(item.year, item.month - 1, sortedDays[0])
            const endDate = new Date(item.year, item.month - 1, sortedDays[sortedDays.length - 1])
            dateLabel = `${Moment(startDate).format('DD/MM/YYYY')} - ${Moment(endDate).format('DD/MM/YYYY')}`
          } else if (sortedDays.length === 1) {
            const singleDate = new Date(item.year, item.month - 1, sortedDays[0])
            dateLabel = Moment(singleDate).format('DD/MM/YYYY')
          } else {
            dateLabel = sortedDays
              .map(day => {
                const dateObj = new Date(item.year, item.month - 1, day)
                return Moment(dateObj).format('DD/MM/YYYY')
              })
              .join(', ')
          }

          const monthYearTitle = Moment(new Date(item.year, item.month - 1, 1)).format('MMMM YYYY')
          const timeFrom = item.timeFrom || '08:00'
          const timeTo = item.timeTo || '18:00'

          return (
            <div key={i} className="border border-grey-300 rounded-[8px] p-4 bg-white">
              <div className="flex justify-between items-center mb-4">
                <h3 className="text-base font-bold capitalize">{monthYearTitle}</h3>
                <img
                  onClick={() => {
                    handleDeleteAvailability(item)
                  }}
                  className="w-[25px] h-[25px] cursor-pointer"
                  src={IconDelete}
                  alt="Eliminar"
                />
              </div>

              <div className="mb-2 text-sm font-medium text-gray-700">Fecha</div>
              <div className="flex items-center w-full h-[40px] bg-grey-50 text-grey-600 rounded-[8px] px-3 mb-4">
                {dateLabel}
              </div>

              <div className="mb-4 flex gap-4 w-full">
                <div className="w-full">
                  <div className="text-sm font-medium text-gray-700">Hora inicio</div>
                  <div className="flex items-center w-full h-[40px] bg-grey-50 text-grey-600 rounded-[8px] px-3">
                    {Moment(timeFrom, 'HH:mm').format('hh:mm A')}
                  </div>
                </div>
                <div className="w-full">
                  <div className="text-sm font-medium text-gray-700">Hora fin</div>
                  <div className="flex items-center w-full h-[40px] bg-grey-50 text-grey-600 rounded-[8px] px-3">
                    {Moment(timeTo, 'HH:mm').format('hh:mm A')}
                  </div>
                </div>
              </div>

              <div className="flex justify-center">
                <button
                  type="button"
                  className="mt-4 min-h-[48px] rounded-[20px] text-button px-[24px] bg-primary-dark text-white border border-primary-dark w-full"
                  onClick={() => handleEdit(item)}
                >
                  Editar
                </button>
              </div>
            </div>
          )
        })}
      </div>

      <AvailabilityModal
        showModal={showModal}
        setShowModal={toggleModal}
        editData={editData}
        onSave={handleSaveAvailability}
        specialistId={params.userId}
      />
    </React.Fragment>
  )
}

export default FormUserCalendar
