import { BreadcrumbElement } from "components/Breadcrumbs/index.interface";
import { useHistory, useParams } from "react-router-dom";
import { routes } from "routes/routing";
import { replacePatientAttentionId } from "./replacePatientAttentionId";
import { replacePatientIdFromRoutes } from "./replacePatientId";
import { NotificationType } from "features/Notifications/services";

export enum BREADCRUMB_ORIGIN {
    PATIENTS,
    PATIENT,
    PATIENT_SERVICE_HISTORY,
    PATIENT_ATTENTION_DETAIL,
    HISTORY,
    MAP,
    NOTIFICATIONS,
}

export function getBreadcrumbDetails({
    location,
    from,
    additionalData,
}: {
    location: Location,
    from: BREADCRUMB_ORIGIN,
    additionalData: any,
}) {
    let resultingElements: BreadcrumbElement[] = [];
    const history = useHistory();
    const params = useParams<any>();

    switch (from) {
        case BREADCRUMB_ORIGIN.MAP:
            resultingElements = [{
                name: "Mapa",
                onClick: () => {
                    history.push("/");
                }
            },{
                name: "Ruta del Día",
                onClick: () => {
                    history.push("/");
                }
            }];
            break;
        case BREADCRUMB_ORIGIN.HISTORY:
            resultingElements = [{
                name: "Pacientes",
                onClick: () => {
                    history.push(routes.clinicHistory.url);
                }
            }, {
                name: "Historial de pacientes",
                onClick: () => {
                    history.push(routes.clinicHistory.url);
                }
            }];
            break;
        case BREADCRUMB_ORIGIN.PATIENTS:
            resultingElements = [{
                name: "Pacientes",
                onClick: () => {
                    history.push(routes.clinicHistory.url);
                }
            }, {
                name: "Pacientes activos",
                onClick: () => {
                    history.push(routes.clinicHistory.url);
                }
            }];
            break;
        case BREADCRUMB_ORIGIN.PATIENT:
            if (location.pathname.includes(replacePatientIdFromRoutes(routes.editPatient.url, params.documentPatient))) {
                resultingElements = [{
                    name: "Pacientes",
                    onClick: () => {
                        history.push(routes.patients.url);
                    }
                }, {
                    name: "Pacientes activos",
                    onClick: () => {
                        history.push(routes.patients.url);
                    }
                }, {
                    name: `${additionalData?.name || ""} ${additionalData?.lastname || ""}`,
                    onClick: () => {
                        history.push(routes.patients.url);
                    }
                }];
            }
            if (location.pathname === replacePatientIdFromRoutes(routes.clinicHistoryPatientV2.url, params.documentPatient)) {
                resultingElements = [{
                    name: "Pacientes",
                }, {
                    name: "Historial de pacientes",
                    onClick: () => {
                        history.push(routes.clinicHistory.url);
                    }
                }, {
                    name: `${additionalData?.name || ""} ${additionalData?.lastname || ""}`
                }];
            }
            if (location.pathname === replacePatientIdFromRoutes(routes.attentionsActionEditPatient.url, params.documentPatient)) {
                resultingElements = [{
                    name: "Atenciones",
                }, {
                    name: "Lista de atenciones",
                    onClick: () => {
                        history.push(routes.clinicHistory.url);
                    }
                }, {
                    name: "Editar paciente",
                }, {
                    name: `${additionalData?.name || ""} ${additionalData?.lastname || ""}`
                }];
            }
            break;
        case BREADCRUMB_ORIGIN.PATIENT_SERVICE_HISTORY:
            if (location.pathname.startsWith(replacePatientIdFromRoutes(routes.attentionsActionEditPatient.url, params.patientId))) {
                resultingElements = [{
                    name: "Atenciones"
                }, {
                    name: "Lista de atenciones",
                    onClick: () => {
                        history.push(routes.attentions.url)
                    }
                }, {
                    name: "Historia clínica",
                }, {
                    name: `${additionalData?.name || ""} ${additionalData?.lastname || ""}`
                }];
            }
            if (location.pathname.startsWith(replacePatientIdFromRoutes(routes.clinicHistorySpecificAttentionsPatient.url, params.documentPatient))) {
                resultingElements = [{
                    name: "Pacientes"
                }, {
                    name: "Historial de pacientes",
                    onClick: () => {
                        history.push(routes.clinicHistory.url)
                    }
                }, {
                    name: `${additionalData?.name || ""} ${additionalData?.lastname || ""}`
                }];
            }
            if (location.pathname.startsWith(replacePatientIdFromRoutes(routes.patientHistoryElement.url, params.documentPatient))) {
                resultingElements = [{
                    name: "Pacientes",
                    onClick: () => {
                        history.push(routes.patients.url);
                    }
                }, {
                    name: "Pacientes activos",
                    onClick: () => {
                        history.push(routes.patients.url)
                    }
                }, {
                    name: "Historial de atenciones",
                    onClick: () => {
                        history.push(routes.patients.url)
                    }
                }, {
                    name: `${additionalData?.name || ""} ${additionalData?.lastname || ""}`,
                    onClick: () => {
                        history.push(routes.attentionsActionPatientAttentionsHistory.url.replace(":documentPatient", additionalData?.userId))
                    }
                }];
            }
            if (location.pathname.startsWith(replacePatientIdFromRoutes(routes.attentionsActionPatientAttentionsHistory.url, params.documentPatient))) {
                resultingElements = [{
                    name: "Atenciones",
                    onClick: () => {
                        history.push(routes.attentions.url);
                    }
                }, {
                    name: "Lista de atenciones",
                    onClick: () => {
                        history.push(routes.attentions.url)
                    }
                }, {
                    name: `${additionalData?.name || ""} ${additionalData?.lastname || ""}`,
                    onClick: () => {
                        history.push(routes.attentionsActionPatientAttentionsHistory.url.replace(":documentPatient", additionalData?.userId))
                    }
                }];
            }
            if (location.pathname === routes.attentions.url) {
                resultingElements = [{
                    name: "Atenciones",
                    onClick: () => {
                        history.push(routes.attentions.url);
                    }
                }, {
                    name: "Lista de Atenciones",
                    onClick: () => {
                        history.push(routes.attentions.url)
                    }
                }];
            }
            break;
        case BREADCRUMB_ORIGIN.PATIENT_ATTENTION_DETAIL:
            if (location.pathname.startsWith(replacePatientAttentionId(replacePatientIdFromRoutes(routes.attentionsActionPatientAttentionsHistorySpecificAttention.url, params.documentPatient), params.attentionId))) {
                resultingElements = [{
                    name: "Atenciones",
                    onClick: () => {
                        history.push(routes.attentions.url);
                    }
                }, {
                    name: "Lista de Atenciones",
                    onClick: () => {
                        history.push(routes.attentions.url)
                    }
                }, {
                    name: `${additionalData?.name || ""} ${additionalData?.lastname || ""}`,
                    onClick: () => {
                        history.push(routes.attentionsActionPatientAttentionsHistory.url.replace(":documentPatient", additionalData?.userId))
                    }
                }, {
                    name: "Detalles de la atención",
                    onClick: () => { }
                }]
            }


            if (location.pathname.startsWith(replacePatientAttentionId(replacePatientIdFromRoutes(routes.clinicHistorySpecificAttentionForPatient.url, params.documentPatient), params.attentionId))) {
                resultingElements = [{
                    name: "Pacientes",
                    onClick: () => {
                        history.push(routes.clinicHistory.url);
                    }
                }, {
                    name: "Historial de Paciente",
                    onClick: () => {
                        history.push(routes.clinicHistory.url)
                    }
                }, {
                    name: `${additionalData?.name || ""} ${additionalData?.lastname || ""}`,
                    onClick: () => {
                        history.push(routes.clinicHistorySpecificAttentionsPatient.url.replace(":documentPatient", additionalData?.userId))
                    }
                }, {
                    name: "Detalles de la atención",
                    onClick: () => { }
                }]
            }

            if (location.pathname.startsWith(replacePatientAttentionId(replacePatientIdFromRoutes(routes.patientHistoryElementAttentionDetails.url, params.documentPatient), params.attentionId))) {
                resultingElements = [{
                    name: "Activos",
                    onClick: () => {
                        history.push(routes.patients.url);
                    }
                }, {
                    name: "Pacientes activos",
                    onClick: () => {
                        history.push(routes.patients.url);
                    }
                }, {
                    name: "Historial de atenciones",
                    onClick: () => {
                        history.push(routes.patientHistoryElement.url.replace(":documentPatient", additionalData?.userId))
                    }
                }, {
                    name: `${additionalData?.name || ""} ${additionalData?.lastname || ""}`,
                    onClick: () => {
                        history.push(routes.patientHistoryElement.url.replace(":documentPatient", additionalData?.userId))
                    }
                }, {
                    name: "Detalles de la atención",
                    onClick: () => { }
                }]
            }
            break;
        case BREADCRUMB_ORIGIN.NOTIFICATIONS:
            const type = additionalData.type;
            const notificationLabels = {
                [NotificationType.call]: "Llamadas",
                [NotificationType.queryWorkplan]: "Plan de trabajo",
                [NotificationType.report]: "Dispositivos",
                [NotificationType.attended]: "Atendidas",
                [NotificationType.annotations]: "Anotaciones"
            } as any;

            resultingElements = [{
                name: "Notificaciones"
            }, {
                name: notificationLabels[type] || ""
            }];
            break;
    }

    return {
        resultingElements
    }
}