import React, { useState, useEffect } from 'react'
import Moment from 'moment'
import ExitIcon from 'assets/icons/icon_close.svg'
import 'moment/locale/es'

// Componentes para selección de fechas y horas
import InputDatePicker from 'components/InputDatePicker'
import Calendar from './Calendar'
import TimeInput from './TimeInput'

const CardDate = (props: any) => {
  const [currentCalendarType, setCurrentCalendarType] = useState<number>(1)
  const [isOpen, setIsOpen] = useState(false)
  const [firtsOpen, setFirtsOpen] = useState(false)

  // Estados para selección múltiple de fechas
  const [multiDate, setMultiDate] = useState<any>([])
  const [multiDateFormat, setMultiDateFormat] = useState<any>([])

  // Estados para selección de rango
  const [startDateRange, setStartDateRange] = useState<Date>(new Date())
  const [endDateRange, setEndDateRange] = useState<Date>(new Date())

  // Estados para horas (en formato 24h, ej: "08:00")
  const [timeFrom, setTimeFrom] = useState('08:00')
  const [timeTo, setTimeTo] = useState('18:00')

  const onChangeRange = (dates: any) => {
    const [start, end] = dates
    setStartDateRange(start)
    setEndDateRange(end)
  }

  const handleClick = () => {
    setIsOpen(!isOpen)
    setFirtsOpen(true)
  }

  useEffect(() => {
    Moment.locale('es')
  }, [])

  // Inicializa datos si se pasa initialData (modo edición)
  useEffect(() => {
    setIsOpen(false)
    if (props.initialData) {
      const ordenDay = props.initialData.days.sort((a: number, b: number) => a - b)
      const startDate = new Date(props.initialData.year, props.initialData.month - 1, ordenDay[0], 12)
      const endDate = new Date(props.initialData.year, props.initialData.month - 1, ordenDay[ordenDay.length - 1], 12)
      // Si los días no son consecutivos, se asume multi-date
      if (ordenDay[0] + ordenDay.length - 1 !== ordenDay[ordenDay.length - 1]) {
        setCurrentCalendarType(2)
        let arrayDateMultiFormat: any = []
        ordenDay.forEach((d: number) => {
          const dateMulti = new Date(props.initialData.year, props.initialData.month - 1, d, 12)
          arrayDateMultiFormat.push(Moment(dateMulti).format('DD/MM/YYYY'))
        })
        setMultiDateFormat(arrayDateMultiFormat)
      } else {
        setStartDateRange(startDate)
        setEndDateRange(endDate)
      }
      if (props.initialData.timeFrom) {
        setTimeFrom(props.initialData.timeFrom)
      }
      if (props.initialData.timeTo) {
        setTimeTo(props.initialData.timeTo)
      }
    }
  }, [props.initialData])

  // Título para el encabezado (ej: "Octubre 2024")
  const monthYearTitle = React.useMemo(() => {
    if (currentCalendarType === 1) {
      return Moment(startDateRange).format('MMMM YYYY')
    } else {
      if (multiDate.length > 0) {
        return Moment(multiDate[0]).format('MMMM YYYY')
      }
      return 'Selecciona fecha'
    }
  }, [currentCalendarType, startDateRange, multiDate])

  // Cuando se hace clic en Guardar/Actualizar
  const handleSave = () => {
    const newData = {
      currentCalendarType,
      startDateRange,
      endDateRange,
      multiDate,
      timeFrom,
      timeTo,
    }
    // Envía el objeto directamente al padre
    props.onSubmit(newData)
    setIsOpen(false)
  }

  return (
    <React.Fragment>
      <div className="w-full relative bg-white rounded-xl border border-grey-400 px-4 py-4">
        {/* Encabezado: Mes Año y botón de eliminar */}
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-base font-bold capitalize">{monthYearTitle}</h3>
          <img
            onClick={() => {
              props.trashClick()
            }}
            className="w-[25px] h-[25px] cursor-pointer"
            src={ExitIcon}
            alt="Salir"
          />
        </div>

        {/* Sección de Fecha */}
        <div className="mb-2 text-sm font-medium text-gray-700">Fecha</div>
        <div
          className="flex items-center w-full h-[40px] bg-grey-50 text-grey-600 rounded-[8px] px-3 mb-4 cursor-pointer"
          onClick={handleClick}
        >
          {currentCalendarType === 1
            ? `${Moment(startDateRange).format('DD/MM/YYYY')}, ${Moment(endDateRange).format('DD/MM/YYYY')}`
            : multiDateFormat.join(', ')}
        </div>

        {/* Sección de Horas */}
        <div className="mb-4 flex gap-4 w-full">
          <TimeInput
            label="Hora inicio"
            className="w-full"
            value={timeFrom}
            onChange={(newValue: string) => setTimeFrom(newValue)}
          />
          <TimeInput
            label="Hora fin"
            className="w-full"
            value={timeTo}
            onChange={(newValue: string) => setTimeTo(newValue)}
          />
        </div>

        {/* Botón Guardar/Actualizar */}
        <button
          disabled={
            !firtsOpen ||
            isOpen ||
            (currentCalendarType === 1 ? !startDateRange || !endDateRange : multiDate.length === 0)
          }
          type="button"
          onClick={handleSave}
          className="mt-4 min-h-[48px] rounded-[20px] text-button px-[24px] bg-primary-dark text-white border border-primary-dark disabled:bg-grey-50 disabled:text-grey-200 disabled:border-grey-50 w-full"
        >
          {props.initialData ? 'Actualizar' : 'Guardar'}
        </button>
      </div>

      {/* Popover para DatePicker */}
      {isOpen && (
        <div className="relative react-datepicke-content rounded-xl border border-grey-400 bg-white mt-2 p-4 translate-x-[100%] left-1/2">
          <div className="bg-white pb-2">
            <div className="text-subtitle1 font-medium mb-4">¿Qué días se asignarán?</div>
            <div className="flex w-full justify-between mb-4 gap-2">
              <div
                onClick={() => setCurrentCalendarType(1)}
                className={`cursor-pointer flex items-center justify-center min-h-[36px] w-full rounded-[8px] text-caption ${
                  currentCalendarType === 1
                    ? 'bg-primary-main text-white'
                    : 'border border-primary-main text-primary-main'
                }`}
              >
                Por rango
              </div>
              <div
                onClick={() => setCurrentCalendarType(2)}
                className={`cursor-pointer flex items-center justify-center min-h-[36px] w-full rounded-[8px] text-caption ${
                  currentCalendarType === 2
                    ? 'bg-primary-main text-white'
                    : 'border border-primary-main text-primary-main'
                }`}
              >
                Por día
              </div>
            </div>
          </div>

          {currentCalendarType === 1 ? (
            <InputDatePicker
              id={props.id + 'ppp'}
              selected={startDateRange}
              onChange={onChangeRange}
              startDate={startDateRange}
              endDate={endDateRange}
              selectsRange
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
              locale="es"
              dateFormat="dd/MM/yyyy"
              minDate={new Date()}
              maxDate={new Date(parseInt(Moment().format('YYYY')) + 3, 12, 31)}
              inline={true}
            />
          ) : (
            <Calendar
              id={props.id + 'rrr'}
              initialData={props.initialData}
              className="calendar-content"
              setData={(data: any) => {
                const adjustedData = data.map((element: any) => {
                  let dateObj: Date
                  if (typeof element.date === 'string') {
                    dateObj = Moment(element.date, 'YYYY-MM-DD').toDate()
                  } else {
                    dateObj = new Date(element.date)
                  }
                  dateObj.setHours(12, 0, 0, 0)
                  return {
                    date: dateObj,
                    format: Moment(dateObj).format('DD/MM/YYYY'),
                  }
                })
                let dataDate = adjustedData.map((el: { date: any }) => el.date)
                let dataDateFormat = adjustedData.map((el: { format: any }) => el.format)
                setMultiDate(dataDate)
                setMultiDateFormat(dataDateFormat)
              }}
            />
          )}

          <button
            onClick={() => {
              setIsOpen(false)
            }}
            type="button"
            className="mt-4 min-h-[48px] rounded-[20px] text-button px-[24px] bg-primary-dark text-white border border-primary-dark w-full max-w-[343px]"
          >
            Aplicar
          </button>
        </div>
      )}
    </React.Fragment>
  )
}

export default CardDate
