import React from 'react'
import Modal from 'components/modal'
import CardDate from './cardDate'

interface AvailabilityModalProps {
  showModal: boolean
  setShowModal: () => void
  editData: any // null => nueva disponibilidad; objeto => edición
  onSave: (data: any) => void
  specialistId: number | string
}

const AvailabilityModal: React.FC<AvailabilityModalProps> = ({
  showModal,
  setShowModal,
  editData,
  onSave,
  specialistId,
}) => {
  // Recibe el objeto final del hijo y lo envía al padre
  const handleSubmit = (formData: any) => {
    const finalData = { ...formData, specialistsId: specialistId }
    onSave(finalData)
  }

  return (
    <>
      <Modal
        showModal={showModal}
        text={editData ? 'Editar Disponibilidad' : 'Nueva Disponibilidad'}
        btn="Cerrar"
        setShowModal={setShowModal}
      />
      {showModal && (
        <div className="fixed inset-0 z-[70] flex items-center justify-center">
          <div className="p-4 w-full max-w-[600px] bg-white rounded-md shadow-lg">
            <CardDate
              initialData={editData} // Edición o nuevo
              onSubmit={handleSubmit} // Envía data final al padre
              trashClick={setShowModal} // Cierra modal
            />
          </div>
        </div>
      )}
    </>
  )
}

export default AvailabilityModal
