import { useEffect, useRef, useState } from 'react'
import DatePicker, { DateObject } from 'react-multi-date-picker'
import Moment from 'moment'

export default function Calendar(props: any) {
  const weekDays = ['Do', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sá']
  const months = [
    'Enero',
    'Febrero',
    'Marzo',
    'Abril',
    'Mayo',
    'Junio',
    'Julio',
    'Agosto',
    'Setiembre',
    'Octubre',
    'Noviembre',
    'Deciembre',
  ]
  const datePickerRef = useRef<any>()
  const [values, setValues] = useState([])

  useEffect(() => {
    datePickerRef.current.openCalendar()

    if (props.initialData) {
      const ordenDay = props.initialData.days.sort((a: number, b: number) => {
        return a - b
      })

      let arrayDateMulti: any = []

      ordenDay.forEach((day: any) => {
        let dateMulti = new Date(props.initialData.year, props.initialData.month - 1, day, 12)
        arrayDateMulti.push(dateMulti)
      })
      setValues(arrayDateMulti)
    }
  }, [])
  useEffect(() => {
    /*     console.log(values);
     */
    let arrayDatw = JSON.parse(JSON.stringify(values))
    let newArrayDatw = arrayDatw.map((element: any) => {
      return {
        date: new Date(element),
        format: Moment(new Date(element)).format('DD/MM/YYYY'),
      }
    })

    props.setData(newArrayDatw)
  }, [values])
  return (
    <DatePicker
      {...props}
      value={values}
      onChange={setValues}
      onClose={() => false}
      onOpen={() => true}
      multiple
      ref={datePickerRef}
      weekStartDayIndex={1}
      weekDays={weekDays}
      months={months}
      minDate={new Date()}
      /* onChange={(dateObject) => {
     console.log(JSON.stringify(dateObject));
     
     let arrayDatw = JSON.parse(JSON.stringify(dateObject));
     let newArrayDatw = arrayDatw.map((element: any) => {

       return ({
         date: new Date(element),
         format: Moment(new Date(element)).format('DD/MM/YYYY')
       })
     });
     let newArrayDatw2 = arrayDatw.map((element: any) => {

       return ({
         date: new Date(element),
       })
     });
     setValues(newArrayDatw2);

     props.setData(newArrayDatw)
   }}  */
    />
  )
}
